// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-pages-404-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-support-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("/Users/jackqiao/Documents/htdoc/gramara-com/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

